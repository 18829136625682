import React from 'react';
import { TestimonialCard } from '../../components/TestimonialCard/TestimonialCard';
import styles from './AboutPage.module.css';
import pastaCastle from '../../assets/img/pasta-castle.png';
import manIcon from '../../assets/img/man-icon.png';
import womanIcon from '../../assets/img/woman-icon.png';
import pastaCompany from '../../assets/img/pasta-company.png';
import resumeIcon from '../../assets/img/resume-icon.svg';





const testimonials = [
  {
    title: "Nevjerovatno ukusna tjestenina",
    content: "Uživali smo u svakom zalogaju naše omiljene tjestenine. Svaki oblik je odlično komponovan, a okusi su bogati i autentični. Bez sumnje, ovo je najbolje mjesto za ljubitelje tjestenine.",
    author: "Amir Hasanović",
    location: "Sarajevo, BiH",
    image: manIcon
  },
  {
    title: "Pasta koja oduzima dah",
    content: "Kada sam prvi put kušao Sani tjesteninu, bio sam oduševljen. Svaki jelo sa njom je posebna delicija, a kombinacija okusa u svakoj porciji je jednostavno savršena. Nikada bolja tjestenina.",
    author: "Vahidin Kovačević",
    location: "Tešanj, BiH",
    image: manIcon
  },
  {
    title: "Specijaliteti sa tjesteninom kao umjetnost",
    content: "Svaka tjestenina iz Sinanovic doo je poput umjetničkog djela. Sklad okusa, tekstura i prezentacija čine svaki obrok nezaboravnim iskustvom. Svakako preporučujem isprobati njihovu tjesteninu!",
    author: "Elma Delić",
    location: "Tuzla, BiH",
    image: womanIcon
  }
];

const features = [
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/af11e709379d502031bce3f5a2cfc5a582901b0b88fe341bc5b73784ea34e9cb?placeholderIfAbsent=true&apiKey=bef8a3b2ba49437a8b71b1f8eeff8143',
    title: 'Jednostavno za naručiti',
    description: 'Jednostavno naručivanje – omiljeni okusi na samo nekoliko poteza! Pozovite nas!'
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/249a2160e622122a76b69c277e5e5da2f34e4eb30988d012efef58a3ef30341e?placeholderIfAbsent=true&apiKey=bef8a3b2ba49437a8b71b1f8eeff8143',
    title: 'Uvijek brz i zdrav obrok!',
    description: 'Svježina na vašem stolu u najkraćem roku!'
  }
];

const AboutPage = () => {
  return (
    <div className={styles.page}>
      <section className={styles.heroSection}>
        <img src={pastaCastle} alt="Pasta Castle" className={styles.heroImage} />
        <div className={styles.heroContent}>
          <h1>Zdrava hrana za svakoga.</h1>
          <p>Naša firma osnovana je 2000. godine s misijom da na trpeze naših kupaca donesemo najkvalitetniju tjesteninu. Smješteni u srcu Bosne i Hercegovine, u Tešnju, ponosno proizvodimo tjesteninu i razne proizvode od tjestenine, kombinirajući bogatu domaću tradiciju s najsavremenijim standardima kvaliteta.
            Kako bismo osigurali vrhunsku sigurnost i kvalitetu svih naših proizvoda, implementirali smo HACCP sistem, koji garantuje strogu kontrolu u svakom koraku proizvodnog procesa.
            Uz posvećen rad našeg tima od osam vrijednih zaposlenika, uspjeli smo osvojiti povjerenje naših kupaca širom Bosne i Hercegovine. Svaki naš proizvod pažljivo je pripremljen kako bismo osigurali vrhunski okus i kvalitet koji zadovoljavaju i najzahtjevnija nepca.
            </p>
          <p>Naš cilj je nastaviti graditi tradiciju, pružajući našim kupcima samo najbolje – od srca, iz Tešnja!</p>
        </div>
      </section>

      <section className={styles.featuresSection}>
        <div className={styles.featuresBanner}>
          <img src={pastaCompany} alt="Company ambiance" className={styles.bannerImage} />
          <div className={styles.bannerContent}>
            <img src={resumeIcon} alt="Decorative icon" className={styles.bannerIcon} />
            <h2 className={styles.transparentBg}>Osjeti originalan i autentičan okus kod nas.</h2>
          </div>
        </div>
        <div className={styles.featuresGrid}>
          {features.map((feature, index) => (
            <div key={index} className={styles.featureCard}>
              <img src={feature.icon} alt={feature.title} className={styles.featureIcon} />
              <div className={styles.featureContent}>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.testimonialsSection}>
        <h2>Šta kažu oni koji su probali?</h2>
        <div className={styles.testimonialGrid}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default AboutPage;