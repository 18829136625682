import React from 'react';
import styles from './Navigation.module.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/sinanovic.png';

export const Navigation = ({ activeNav, setActiveNav }) => {
  const navigate = useNavigate();
  // const cartItemCount = 5;
  const menuItems = [
    { 'eng': 'Home', 'bos': 'Početak' },
    { 'eng': 'About', 'bos': 'O nama' },
    { 'eng': 'Menu', 'bos': 'Katalog' },
    { 'eng': 'Contact', 'bos': 'Kontakt' }
  ];


  const handleClick = (item) => {
    setActiveNav(item);
    navigate(`/${item.toLowerCase()}`);
  }

  return (
    <nav className={styles.navigation}>
      <div className={styles.logo}>
        <img src={logo} alt="Sinanovic Logo" className={styles.logoImage} />
        <span className={styles.logoText}>Sinanović doo</span>
      </div>
      <div className={styles.menuItems}>
        {menuItems.map((item, index) => (
          <button
            key={index}
            className={`${styles.menuItem} ${item.eng === activeNav ? styles.active : ''}`}
            tabIndex="0"
            onClick={() => handleClick(item.eng)}
          >
            {item.bos}
          </button>
        ))}
      </div>
      {/* <button onClick={() => navigate("/cart")} className={styles.bookButton} tabIndex="0">
        <img src={shoppingCart} height="30px" />
        {cartItemCount > 0 && (
          <span className={styles.cartCount}>{cartItemCount}</span>
        )}
      </button> */}
    </nav>
  );
};