import React, { useState } from 'react';
import styles from './MenuSection.module.css';
import { MenuCard } from '../../components/MenuCard/MenuCard';
import pilavGreenImg from '../../assets/img/products/domaci_pilav_spinat.png';
import fidelinImg from '../../assets/img/products/fidelin.png';
import fidelinSImg from '../../assets/img/products/fidelin_sitni.png';
import makaronMImg from '../../assets/img/products/makaron_mali.png';
import makaronVImg from '../../assets/img/products/makaron_veliki.png';
import makaronPImg from '../../assets/img/products/makaron_picolo.png';
import rezanacSImg from '../../assets/img/products/rezanac_siroki.png';
import rezanacUImg from '../../assets/img/products/rezanac_uski.png';
import makaronSImg from '../../assets/img/products/makaron_srednji.png';
import spiralaMixImg from '../../assets/img/products/spirala_mix.png';
import spiralaImg from '../../assets/img/products/spirala.png';
import tarhanaKImg from '../../assets/img/products/tarhana_kisela.png';
import tarhanaSImg from '../../assets/img/products/tarhana_slatka.png';
import zvjezdiceImg from '../../assets/img/products/zvjezdice.png';
import skoljkiceImg from '../../assets/img/products/skoljkice.png';
import hurmasicaPImg from '../../assets/img/products/hurmasica_p.png';
import hurmasicaImg from '../../assets/img/products/hurmasica.png';
import peceniSecerImg from '../../assets/img/products/peceni_secer.png';
import peceniSecerCImg from '../../assets/img/products/peceni_secer_cokolada.png';
import fidelinDImg from '../../assets/img/products/domaci_fidelin.png';
import pilavImg from '../../assets/img/products/domaci_pilav.png';
import pilavSImg from '../../assets/img/products/domaci_pilav_siroki.png';

const menuItems = [
  {
    type: "tjestenina",
    image: fidelinImg,
    title: "Fidelin",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: fidelinSImg,
    title: "Fidelin sitni",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: rezanacSImg,
    title: "Rezanac široki",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: rezanacUImg,
    title: "Rezanac uski",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "domaći",
    image: spiralaMixImg,
    title: "Spirala mix",
    description: "Sastojci: Tjestenina(pšenična krupica T-400, biljni ekstrakti i voda); Tjestenina sa paradajzom(pšenična krupica T-400, paradajz u prahu i voda); Tjestenina sa špinatom(pšenična krupica T-400, špinat u prahu, voda)",
    highlight: "BEZ ADITIVA I KONZERVANSA"
  },
  {
    type: "tjestenina",
    image: spiralaImg,
    title: "Spirala",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: makaronVImg,
    title: "Makaron veliki",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: makaronMImg,
    title: "Makaron mali",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: makaronSImg,
    title: "Makaron srednji",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: makaronPImg,
    title: "Makaron picolo",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: skoljkiceImg,
    title: "Školjkice",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  }
  ,
  {
    type: "tjestenina",
    image: zvjezdiceImg,
    title: "Zvjezdice",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: tarhanaKImg,
    title: "Tarhana kisela",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  },
  {
    type: "tjestenina",
    image: tarhanaSImg,
    title: "Tarhana slatka",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti."
  }, 
  {
    type: "domaći",
    image: pilavImg,
    title: "Domaći pilav",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti.",
    highlight: "BEZ ADITIVA I KONZERVANSA"
  },
  {
    type: "domaći",
    image: pilavSImg,
    title: "Domaći široki pilav",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti.",
    highlight: "BEZ ADITIVA I KONZERVANSA"
  },
  {
    type: "domaći",
    image: fidelinDImg,
    title: "Domaći fidelin",
    description: "Sastav: Brašno tip 400-krupičasto, voda i biljni ekstrakti.",
    highlight: "BEZ ADITIVA I KONZERVANSA"
  },
  {
    type: "domaći",
    image: pilavGreenImg,
    title: "Domaći pilav sa špinatom",
    description: "Sastav: Brašno tip 400-krupičasto, voda i špinat u prahu.",
    highlight: "BEZ ADITIVA I KONZERVANSA"
  },
  {
    type: "domaći",
    image: hurmasicaImg,
    title: "Domaća tradicionalna hurmašica",
    description: "Sastav: jaja, brašno, ulje, biljna mast i šećer."
  },
  {
    type: "domaći",
    image: hurmasicaPImg,
    title: "Domaća pekmezli hurmašica",
    description: "Sastav: jaja, brašno, ulje, biljna mast, pekmez i šećer."
  },
  {
    type: "domaći",
    image: peceniSecerCImg,
    title: "Domaći pečeni šećer sa okusom čokolade",
    description: "Sastav: mlijeko, šećer i čokolada."
  },
  {
    type: "domaći",
    image: peceniSecerImg,
    title: "Domaći pečeni šećer",
    description: "Sastav: mlijeko i šećer."
  }
];


export const MenuSection = () => {
  const [active, setActive] = useState("Sve");
  const [selectedItems, setSelectedItems] = useState(menuItems);

  const handleClick = (category) => {
    setActive(category);
    if (category === 'Domaći proizvodi') {
      setSelectedItems(menuItems.filter(item => item.type === 'domaći'));
    } else if (category === 'Tjestenina') {
      setSelectedItems(menuItems.filter(item => item.type === 'tjestenina'));
    } else {
      setSelectedItems(menuItems);
    }
  }

  

  

  const categories = ['Sve', 'Domaći proizvodi', 'Tjestenina'];

  return (
    <section className={styles.menuSection}>
      <div className={styles.header}>
        <h1 className={styles.title}>Naš katalog</h1>
        <p className={styles.subtitle}>
          Uzimamo u obzir sve pokretače promjena i pružamo vam sve potrebne elemente kako biste ostvarili pravu promjenu.
        </p>
      </div>

      <div className={styles.categories}>
        {categories.map((category, index) => (
          <button
            onClick={() => handleClick(category)}
            key={index}
            className={`${styles.categoryButton} ${active === category ? styles.active : ''}`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className={styles.menuGrid}>
        {selectedItems.map((item, index) => (
          <MenuCard key={index} {...item} />
        ))}
      </div>
    </section>
  );
};
