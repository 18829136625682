import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AboutPage from './pages/AboutPage/AboutPage';
import { MenuPage } from './pages/MenuPage/MenuPage';
import { HomePage } from './pages/HomePage/HomePage';
import ContactPage from './pages/ContactPage/ContactPage';
import { TopBar } from './components/Topbar/Topbar';
import { Navigation } from './components/Navigation/Navigation';
import { Footer } from './components/Footer/Footer';





function App() {
    const [activeNav, setActiveNav] = useState("Home");
    return (
        <Router>
            <Helmet>
                <title>Sinanovic doo</title>
                <link rel="icon" href="./assets/img/sinanovic.jpg" />
            </Helmet>
            <TopBar />
            <Navigation activeNav={activeNav} setActiveNav={setActiveNav} />
            <Routes>
                <Route path="*" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/contact" element={<ContactPage />} />
            </Routes>
            <Footer setActiveNav={setActiveNav} />
        </Router>
    );
}

export default App;