import React from 'react';
import styles from './HeroSection.module.css';
import { Button } from '../../components/Button/Button';
import homeBackground from '../../assets/img/home-background.png';
import { useNavigate } from 'react-router-dom';
import katalogPDF from '../../assets/sinanovicdoo_katalog.pdf';

export const HeroSection = () => {
  const navigate = useNavigate();

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = katalogPDF;
    link.download = 'sinanovicdoo_katalog.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className={styles.heroContainer}>
      <div className={styles.heroBackground}>
        <div className={styles.heroContent}>
          <img
            loading="lazy"
            src={homeBackground}
            className={styles.heroImage}
            alt="Restaurant ambiance background"
          />
          <div className={styles.contentWrapper}>
            <div className={styles.textContent}>
              <h1 className={styles.heroTitle}>
                Najbolja hrana za<br />tvoj ukus
              </h1>
              <p className={styles.heroDescription}>
                Pronađite vrhunsku tjesteninu izrađenu s ljubavlju i pažnjom u našoj domaćoj proizvodnji.
              </p>
            </div>
            <div className={styles.buttonGroup}>
              <Button onClick={handleDownload} variant="primary">Preuzmi katalog</Button>
              <Button onClick={() => navigate("/menu")} variant="secondary">Pogledaj katalog</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};