import React from 'react';
import styles from './InfoSection.module.css';
import citiesImg from '../../assets/img/cities.jpg';

export const InfoSection = () => {
  return (
    <section className={styles.infoSection}>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <h2 className={styles.title}>Dostupnost po cijeloj BiH</h2>
          <p className={styles.description}>
            Možete nas pronaći u prodavnicama svih gradova u Bosni i Hercegovini.
          </p>
        </div>
        <div className={styles.appGrid}>
          <img src={citiesImg} alt='Cities in BiH'/>
        </div>
      </div>
    </section>
  );
};