import React, { useState } from "react";
import emailjs from "emailjs-com";
import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_w1pgbuw", // Replace with your EmailJS Service ID
        "template_sikqaea", // Replace with your EmailJS Template ID
        formData,
        "j-BDW18fZiT0kFvmo" // Replace with your EmailJS User ID (found in dashboard)
      )
      .then(
        (result) => {
          alert("Poruka uspješno poslana!");
          setFormData({ name: "", email: "", subject: "", message: "" });
        },
        (error) => {
          alert("Greška pri slanju poruke, pokušajte ponovo.");
        }
      );
  };

  return (
    <form className={styles.contactForm} onSubmit={handleSubmit}>
      <div className={styles.formContent}>
        <div className={styles.formRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="name" className={styles.inputLabel}>
              Ime
            </label>
            <input
              type="text"
              id="name"
              className={styles.formInput}
              placeholder="Napiši ime"
              aria-label="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="email" className={styles.inputLabel}>
              Email
            </label>
            <input
              type="email"
              id="email"
              className={styles.formInput}
              placeholder="Napiši email adresu"
              aria-label="Enter email address"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="subject" className={styles.inputLabel}>
              Naslov
            </label>
            <input
              type="text"
              id="subject"
              className={styles.formInput}
              placeholder="Napiši naslov"
              aria-label="Write a subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="message" className={styles.inputLabel}>
              Poruka
            </label>
            <textarea
              id="message"
              className={styles.messageInput}
              placeholder="Napiši poruku"
              aria-label="Write your message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>
          Pošalji
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
