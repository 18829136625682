import React from 'react';
import styles from './InfoCard.module.css';

export const InfoCard = ({ title, content, isPhone }) => {
  return (
    <div className={styles.infoCard}>
      <div className={styles.title}>{title}</div>
      <div className={isPhone ? styles.phoneNumber : styles.content}>
        {content}
      </div>
    </div>
  );
};