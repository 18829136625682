import React from 'react';
import styles from './Topbar.module.css';
import facebookIcon from '../../assets/img/facebook-icon.png';
import instagramIcon from '../../assets/img/instagram-icon.png';
import twitterIcon from '../../assets/img/twitter-icon.png';
import phoneIcon from '../../assets/img/phone-icon.svg';
import emailIcon from '../../assets/img/email-icon.svg';


export const TopBar = () => {


  const socialIcons = [
    { src: facebookIcon, alt: "Facebook" },
    { src: instagramIcon, alt: "Instagram" },
    { src: twitterIcon, alt: "Twitter" }
  ];

  return (
    <div className={styles.topBar}>
      <div className={styles.container}>
        <div className={styles.contactInfo}>
          <div className={styles.contactItem}>
            <img src={phoneIcon} alt="Phone" className={styles.icon} />
            <span>032 655 302</span>
          </div>
          <div className={styles.contactItem}>
            <img src={emailIcon} alt="Email" className={styles.icon} />
            <span>sinpek@bih.net.ba</span>
          </div>
        </div>

        <div className={styles.rightSide}>

          {/* Login and Register Buttons */}
          {/* {!isAuthenticated() ?
            (<div className={styles.authButtons}>
              <button onClick={() => navigate("/login")} className={styles.loginButton}>Login</button>
              <button onClick={() => navigate("/register")} className={styles.registerButton}>Register</button>
            </div>) :
            (<div className={styles.authButtons}><button onClick={handleLogout} className={styles.logoutButton}>Logout</button></div>)} */}


          <div className={styles.socialIcons}>
            {socialIcons.map((icon, index) => (
              <img
                key={index}
                src={icon.src}
                alt={icon.alt}
                className={styles.socialIcon}
                onClick={() => window.open('https://www.facebook.com/Sinanovicdoo', '_blank')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
