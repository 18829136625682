import React from 'react';
import styles from './MenuSelect.module.css';
import { useNavigate } from 'react-router-dom';

export const MenuSelect = ({ icon, title, description }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <img src={icon} alt={`${title} icon`} className={styles.cardIcon} />
        <div className={styles.cardText}>
          <h3 className={styles.cardTitle}>{title}</h3>
          <p className={styles.cardDescription}>{description}</p>
        </div>
        <button onClick={() => {navigate("/menu")}} className={styles.exploreButton} tabIndex="0">
          Pogledajte naš katalog
        </button>
      </div>
    </div>
  );
};