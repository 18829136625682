import React from 'react';
import styles from './MenuPage.module.css';
import { InfoSection } from '../../containers/InfoSection/InfoSection';
import { MenuSection } from '../../containers/MenuSection/MenuSection';

export const MenuPage = () => {
  return (
    <div className={styles.page}>
      <main>
        <MenuSection />
        <InfoSection />
      </main>
    </div>
  );
};