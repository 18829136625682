import React from 'react';
import styles from './HomePage.module.css';
import { TestimonialCard } from '../../components/TestimonialCard/TestimonialCard';
import { MenuSelect } from '../../components/MenuSelect/MenuSelect';
import { HeroSection } from '../../containers/HeroSection/HeroSection';
import domesticIcon from '../../assets/img/domestic-icon.png';
import pastaIcon from '../../assets/img/pasta-icon.png';
import pastaCastle from '../../assets/img/pasta-castle.png';
import manIcon from '../../assets/img/man-icon.png';
import womanIcon from '../../assets/img/woman-icon.png';
import { useNavigate } from 'react-router-dom';



const menuCards = [
    {
        icon: pastaIcon,
        title: "Tjestenina",
        description: "Doživite savršenstvo domaće kuhinje uz našu tjesteninu! Pripremljena s pažnjom i od najkvalitetnijih sastojaka, donosi okus tradicije na vaš stol."
    },
    {
        icon: domesticIcon,
        title: "Domaći proizvodi",
        description: "Doživite tradiciju kroz autentične domaće specijalitete! Naša ponuda uključuje hurmašice, gurabije i druge bosanske delicije, pripremljene s ljubavlju prema izvornim receptima."
    }
];

const testimonials = [
    {
        title: "Nevjerovatno ukusna tjestenina",
        content: "Uživali smo u svakom zalogaju naše omiljene tjestenine. Svaki oblik je odlično komponovan, a okusi su bogati i autentični. Bez sumnje, ovo je najbolje mjesto za ljubitelje tjestenine.",
        author: "Amir Hasanović",
        location: "Sarajevo, BiH",
        image: manIcon
    },
    {
        title: "Pasta koja oduzima dah",
        content: "Kada sam prvi put kušao Sani tjesteninu, bio sam oduševljen. Svaki jelo sa njom je posebna delicija, a kombinacija okusa u svakoj porciji je jednostavno savršena. Nikada bolja tjestenina.",
        author: "Vahidin Kovačević",
        location: "Tešanj, BiH",
        image: manIcon
    },
    {
        title: "Specijaliteti sa tjesteninom kao umjetnost",
        content: "Svaka tjestenina iz Sinanovic doo je poput umjetničkog djela. Sklad okusa, tekstura i prezentacija čine svaki obrok nezaboravnim iskustvom. Svakako preporučujem isprobati njihovu tjesteninu!",
        author: "Elma Delić",
        location: "Tuzla, BiH",
        image: womanIcon
    }
];


export const HomePage = () => {

    const navigate = useNavigate();
    return (
        <div className={styles.page}>
            <main>
                <HeroSection />

                <section className={styles.menuSection}>
                    <h2 className={styles.sectionTitle}>Pretražite naš katalog</h2>
                    <div className={styles.menuGrid}>
                        {menuCards.map((card, index) => (
                            <MenuSelect key={index} {...card} />
                        ))}
                    </div>
                </section>

                <section className={styles.aboutSection}>
                    <img src={pastaCastle} alt="Pasta Castle" className={styles.aboutImage} />
                    <div className={styles.aboutContent}>
                        <h2>Zdrava hrana za svakoga.</h2>
                        <p>Naša priča započela je s vizijom stvaranja jedinstvenog iskustva u kuhanju koje spaja tradicionalne okuse, vrhunsku kvalitetu i strast prema tjestenini. Duboko ukorijenjeni u bogatoj kulinarskoj tradiciji, cilj nam je poštovati lokalne korijene dok donosimo inovacije u svaki obrok.</p>
                        <p>U našoj kompaniji vjerujemo da tjestenina nije samo hrana, već prava umjetnost. Naš tim, poznat po predanosti i ljubavi prema svom radu, svakim tanjurom teži stvaranju nezaboravnog iskustva za naše kupce.</p>
                        <button onClick={() => navigate("/about")} className={styles.moreButton} tabIndex="0">Više o nama</button>
                    </div>
                </section>

                <section className={styles.testimonialsSection}>
                    <h2 className={styles.sectionTitle}>Šta kažu oni koji su probali?</h2>
                    <div className={styles.testimonialGrid}>
                        {testimonials.map((testimonial, index) => (
                            <TestimonialCard key={index} {...testimonial} />
                        ))}
                    </div>
                </section>
            </main>
        </div>
    );
};