import React from 'react';
import styles from './ContactInfo.module.css';
import { InfoCard } from '../InfoCard/InfoCard';

export const ContactInfo = () => {
  const contactData = [
    {
      title: "Nazovi nas:",
      content: "032 655 302",
      isPhone: true
    },
    {
      title: "Hours:",
      content: `Pon-Pet: 8am - 4pm;
      Sub-Ned: neradni`,
      isPhone: false
    },
    {
      title: "Naša lokacija:",
      content: "Tešanj, Ulica Patriotske lige 119",
      isPhone: false
    }
  ];

  return (
    <div className={styles.contactInfoContainer}>
      {contactData.map((item, index) => (
        <InfoCard
          key={index}
          title={item.title}
          content={item.content}
          isPhone={item.isPhone}
        />
      ))}
    </div>
  );
};