import React from 'react';
import styles from './Button.module.css';

export const Button = ({ children, variant = 'primary', onClick }) => {
  const buttonClass = variant === 'primary' ? styles.primaryButton : styles.secondaryButton;
  
  return (
    <button 
      className={buttonClass}
      onClick={onClick}
      tabIndex={0}
    >
      {children}
    </button>
  );
};