import React from 'react';
import styles from './ContactPage.module.css';
import ContactForm from '../../components/ContactForm/ContactForm';
import { ContactInfo } from '../../components/ContactInfo/ContactInfo';


export default function ContactPage() {
  return (
    <div className={styles.contact}>
      <div className={styles.background}>
        <div className={styles.titleSection}>
          <h1 className={styles.mainTitle}>Kontaktirajte nas</h1>
          <p className={styles.subtitle}>
          Razmatramo sve pokretače promjena kako bismo vam pružili potrebne elemente za ostvarivanje stvarnih promjena.
          </p>
        </div>
      </div>
      <ContactForm />
      <ContactInfo />
    </div>
  );
}