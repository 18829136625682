import React from 'react';
import styles from './Footer.module.css';
import logo from '../../assets/img/sinanovic.png';
import post1 from '../../assets/img/facebook-post-1.jpg';
import post2 from '../../assets/img/facebook-post-2.jpg';
import post3 from '../../assets/img/facebook-post-3.jpg';
import post4 from '../../assets/img/facebook-post-4.jpg';
import { useNavigate } from 'react-router-dom';

const footerItems = [
  { 'eng': 'Home', 'bos': 'Početak' },
  { 'eng': 'About', 'bos': 'O nama' },
  { 'eng': 'Menu', 'bos': 'Katalog' },
  { 'eng': 'Contact', 'bos': 'Kontakt' }
];


const facebookPosts = [
  { src: post1, alt: "Facebook post 1" },
  { src: post2, alt: "Facebook post 2" },
  { src: post3, alt: "Facebook post 3" },
  { src: post4, alt: "Facebook post 4" }
];

export function Footer({ setActiveNav }) {
  const navigate = useNavigate();

  const handleClick = (item) => {
    setActiveNav(item);
    navigate(`/${item.toLowerCase()}`);
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.divider} />
        <div className={styles.content}>
          <div className={styles.brandSection}>
            <a href="/" className={styles.logo}>
              <img src={logo} alt="Sinanovic doo" />
              <span>Sinanović doo</span>
            </a>
            <p>
              U novoj eri tehnologije gledamo u budućnost s izvjesnošću i ponosom na našu kompaniju i postignuća.
            </p>
          </div>

          <div className={styles.linksSection}>
            <div className={styles.pages}>
              {footerItems.map((item) => (
                <button className={styles.button} onClick={() => handleClick(item.eng)}>{item.bos}</button>
              ))}
            </div>
          </div>

          <div className={styles.facebookSection}>
            <button onClick={() => window.open('https://www.facebook.com/Sinanovicdoo', '_blank')} className={styles.button}>Pratite nas na Facebook</button>
            <div className={styles.facebookGrid}>
              {facebookPosts.map((post, index) => (
                <img
                  key={index}
                  src={post.src}
                  alt={post.alt}
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.divider} />

        <p className={styles.copyright}>
          Copyright © 2024 #musss2003. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}