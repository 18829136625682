import React from 'react';
import styles  from './TestimonialCard.module.css';

export const TestimonialCard = ({ title, content, author, location, image }) => {
    return (
        <div className={styles.testimonialCard}>
            <div className={styles.content}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.text}>{content}</p>
                <div className={styles.divider} />
                <div className={styles.author}>
                    <img src={image} alt={author} className={styles.authorImage} />
                    <div className={styles.authorInfo}>
                        <div className={styles.authorName}>{author}</div>
                        <div className={styles.authorLocation}>{location}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};